import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"
import TopicsList from "/src/components/topics-list"


const Page = ({data}) => {
  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
  ]
  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 
  const years = lang ===  'ja' 
                ? data.yearja.distinct.reduceRight((p, c) => [...p, c], []) 
                : data.yearen.distinct.reduceRight((p, c) => [...p, c], [])

  
  return (
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title={t("お知らせ")} lang={lang} />
      <ContentLayout lang={lang}>
        <h1>{t("お知らせ")}</h1>

        <ul className="anchorlink">

          {years.map((y) => {
            return (
              <li>
                <AnchorLink anchor={"#anchor"+y}>
                  {y}
                  {lang==='ja'?<span className="screen-reader-text">{t("年")}</span>:''}
                  </AnchorLink>
              </li>
            )}
          )}
        </ul>

        {years.map((y) => {
            return(
              <>
                <h2 id={"anchor"+y}>{y}</h2>
                <ul className="topics-list">
                  {data.md.edges.map(({node}) => ( 
                      <>
                        {y === node.frontmatter.year ? <TopicsList lang={lang} node={node} /> : ''}
                      </>
                  ))}
                </ul>
              </>
            )
          }
        )}
      </ContentLayout>
    </Layout>
    )
}
export default Page

export const query = graphql `
  query($language: String!, $draft: [Boolean]!) {
    md: allMarkdownRemark( 
      sort: {fields: [fields___slug], order: DESC}
      filter: {
        frontmatter: {post: {eq: null}}
        fields: {draft: { in: $draft }}
      }
      ) {
      edges { 
        node { 
          frontmatter { 
            year( formatString: "YYYY") 
            postdate:date
            date(formatString: "YYYY年M月D日")
            dateen:date(formatString: "MMM. D, YYYY")
            title 
            titleen
            external
            externalen
            externalext
            internal
            anchor
            nolink
            titlelang
            attachments {
              publicURL
              prettySize
              extension
              base
              relativeDirectory
            }
            attachmentsen {
              publicURL
              prettySize
              extension
              base
              relativeDirectory
            }
            post
          }
          fields {
            slug 
          }
        }
      }
    }

    yearja:allMarkdownRemark(
      sort: {order: DESC, fields: frontmatter___year}
      filter: {
        frontmatter: {title: {ne: ""},post: {eq: null}}
        fields: {draft: { in: $draft }}
      }
      ) {
      distinct(field: frontmatter___year)
    }

    yearen:allMarkdownRemark(
      sort: {order: DESC, fields: frontmatter___year}
      filter: {
        frontmatter: {titleen: {ne: ""},post: {eq: null}}
        fields: {draft: { in: $draft }}
      }
      ) {
      distinct(field: frontmatter___year)
    }

    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
  }
`